.ant-table-title{
    text-align: left;
    font-weight: bold;
}

#variants-table .ant-table-row{
    cursor: pointer;
}

.selected-row{
    background-color: #ebf7ff;
}
